import React from "react";
function ViewIcon() {
  return (
    <div>
      <p>Icon</p>
    </div>
  );
}

export default ViewIcon;
